
<template>
    <div class="stores">
        <div class="stores-title">
            <span>品牌</span>
            <el-button type="primary"  size="small" @click="addPing">新增品牌</el-button>
        </div>
        <div class="cont">
            <ul class="cont-let">
                <li v-for="(item,index) in pinglist" :key="index" @click="saiRntList(item,index)" :class="index == pingIndex ? 'clickClick': '' ">
                    <div>{{ item.name }}</div>
                    <div @click="editPing(item)">编辑</div>
                </li>
            </ul>
            <div class="cont-rnt">
                <div class="cont-rnt-title">
                    <span>门店</span>
                    <el-button type="primary"  size="small" @click="addMenDian">新增门店</el-button>
                </div>
                <el-table
                    :header-cell-style="headCalss"
                    :max-height="heightmAC"
                    :data="tableData"
                    border
                    style="width: 100%">
                    <el-table-column
                        prop="name"
                        align="center"
                        label="门店名称">
                    </el-table-column>
                    <el-table-column
                        prop="premises_id"
                        align="center"
                        label="门店狗号">
                    </el-table-column>
                    <el-table-column
                        prop=""
                        align="center"
                        label="财升门店ID">
                        <template slot-scope="scope"> 
                            {{ scope.row.cs_store_id && scope.row.cs_store_id != 0 ? scope.row.cs_store_id : '' }}
                        </template>
                    </el-table-column>
                     <el-table-column
                        prop="id"
                        align="center"
                        label="操作">
                        <template slot-scope="scope"> 
                            <div class="caoStyle">
                                <span  @click="dellMen(scope.row)">删除</span>
                                <span @click="editMen(scope.row)">修改</span>
                            </div>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
        </div>
        <!-- 品牌弹出层 -->
        <el-dialog
        :title="pingTitle"
        :visible.sync="pingShow"
        width="30%">
            <!-- 品牌 -->
            <div>
                <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
                    <el-form-item label="品牌名称" prop="name">
                        <el-input v-model="ruleForm.name"></el-input>
                    </el-form-item>
                    <el-form-item label="品牌ID">
                        <el-input v-model="ruleForm.pingId"></el-input>
                    </el-form-item>
                </el-form>
                <div class="pingAnniu">
                    <el-button type="danger"  size="medium" @click="dellClik">删除</el-button>
                    <el-button type="primary"  size="medium" @click="baoPing">保存</el-button>
                </div>
            </div>
        </el-dialog>
        <el-dialog
        :title="pingTitle"
        :visible.sync="zhangShow"
        width="30%">
            <div>
                <el-form :model="menForm" :rules="menRules" ref="menForm" label-width="100px" class="demo-ruleForm">
                    <el-form-item label="门店名称" prop="name">
                        <el-input v-model="menForm.name"></el-input>
                    </el-form-item>
                    <el-form-item label="门店狗号" prop="premises_id">
                        <el-input v-model="menForm.premises_id"></el-input>
                    </el-form-item>
                    <el-form-item label="财升门店ID">
                        <el-input v-model="menForm.cs_store_id"></el-input>
                    </el-form-item>
                </el-form>
                <div class="pingAnniu">
                    <el-button  size="medium" @click="zhangShow = !zhangShow">取消</el-button>
                    <el-button type="primary"  size="medium" @click="baoMen">保存</el-button>
                </div>
            </div>
        </el-dialog>
    </div>
</template>
<script>
    import { brandList,addBrand,editBrand,storeList,addStore,editStore,delStore,delBrand } from "../../api/index";
    export default {
        data() {
            return {
                //品牌
                pingIndex:0,
                pinglist:[],
                pingShow:false,
                ruleForm:{
                    name: '',
                    pingId: '',
                },
                rules:{
                    name: [
                        { required: true, message: '请输入品牌名称', trigger: 'blur' }
                    ]
                },
                liItem:null,
                pingRow:null,
                pingTitle:'新增品牌',
                // 门店
                tableData: [],
                heightmAC:window.innerHeight - 220,
                zhangShow:false,
                menForm:{
                    name:'',
                    premises_id:'',
                    cs_store_id:''
                },
                menRules:{
                    name: [
                        { required: true, message: '请输入门店名称', trigger: 'blur' }
                    ],
                    premises_id: [
                        { required: true, message: '请输入门店狗号', trigger: 'blur' }
                    ]
                },
                menRow:null
            }
        },
        created() {
            
            this._brandList()
        },
        methods: {
            // 品牌模块
            _brandList(){
                brandList().then(Er =>{
                    if(Er.data && Er.data.length>0){
                        this.pinglist = Er.data
                        this.pingRow = Er.data[0]
                        this._storeList(Er.data[0])
                    }
                })
            },
            saiRntList(row,index){
                this.pingIndex  = index
                this._storeList(row)
                this.pingRow = row
            },
            addPing(){
                //打开品牌
                this.pingShow = true  
                //清空输入框
                if(this.$refs.ruleForm){
                    this.$refs.ruleForm.resetFields()
                    this.ruleForm = {
                        name: '',
                        pingId: '',
                    }
                }
                this.pingTitle = '新增品牌'
                
            },
            baoPing(){
                let _this = this
                this.$refs['ruleForm'].validate((valid) => {
                    if (valid) {
                        if(_this.pingTitle != '新增品牌'){
                            editBrand({
                                name:_this.ruleForm.name,
                                cs_brand_id:_this.ruleForm.pingId,
                                id:_this.liItem.id
                            }).then(Er =>{
                                if(Er.return_code == 200){
                                    _this.pingShow = false
                                    _this._brandList()
                                    _this.$message({
                                        message: "修改成功",
                                        type: "success",
                                    });
                                    _this.liItem = null
                                }
                            })
                        }else{
                            addBrand({
                                name:_this.ruleForm.name,
                                cs_brand_id:_this.ruleForm.pingId
                            }).then(Er =>{
                                if(Er.return_code == 200){
                                    _this.pingShow = false
                                    _this._brandList()
                                    _this.$message({
                                        message: "添加成功",
                                        type: "success",
                                    });
                                }
                            })
                        }
                        
                    } else {
                        return false;
                    }
                });
            },
            dellClik(){
                let _this = this
                delBrand({
                    brand_id:_this.pingRow.id
                }).then(Er =>{
                    if(Er.return_code == 200){
                        _this.pingShow = false
                        _this._brandList()
                        _this.$message({
                            message: "删除成功",
                            type: "success",
                        });
                    }
                })
            },
            _delBrand(){

                
            },
            editPing(row){
                //打开品牌
                this.pingShow = true  
                //清空输入框
                if(this.$refs.ruleForm){
                    console.log(222)
                    this.$refs.ruleForm.resetFields()
                }
                this.liItem = row
                this.ruleForm = {
                    name:row.name,
                    pingId:row.cs_brand_id
                }
                 this.pingTitle = '修改品牌'
            },
            // 品牌end

            //门店模块
            headCalss(){
                return 'background:#f5f7fa'
            },
            _storeList(row){
                storeList({
                    brand_id:row.id
                }).then(Er =>{
                    if(Er.return_code == 200){
                        if(Er.data && Er.data.length>0){
                            this.tableData  = Er.data
                        }else{
                            this.tableData  = []
                        }
                    }
                })
            },
            addMenDian(){
                this.zhangShow = true
                this.pingTitle = '添加门店'
                   //清空输入框
                if(this.$refs.menForm){
                    this.menForm = {
                        name:'',
                        premises_id:'',
                        cs_store_id:''
                    }
                    this.$refs.menForm.resetFields()
                }
                 this.menForm.cs_store_id = ''
            },
            editMen(row){
                this.zhangShow = true
                this.pingTitle = '编辑门店'
                   //清空输入框
                if(this.$refs.menForm){
                    this.$refs.menForm.resetFields()
                }
                 this.menForm.cs_store_id = ''
                this.menRow = row
                this.menForm = {
                    name:row.name,
                    premises_id:row.premises_id,
                    cs_store_id:row.cs_store_id
                }
                
            },
            baoMen(){
                let _this = this
                this.$refs['menForm'].validate((valid) => {
                    if (valid) {
                        if(_this.pingTitle == '编辑门店'){
                            editStore({
                                store_id:_this.menRow.id,
                                name:_this.menForm.name,
                                premises_id:_this.menForm.premises_id,
                                cs_store_id:_this.menForm.cs_store_id
                            }).then(Er =>{
                                if(Er.return_code == 200){
                                    _this._storeList(_this.pingRow)
                                    _this.zhangShow = false
                                    _this.$message({
                                        message: "修改成功",
                                        type: "success",
                                    });
                                }
                            })
                        }else{
                            addStore({
                                brand_id:_this.pingRow.id,
                                name:_this.menForm.name,
                                premises_id:_this.menForm.premises_id,
                                cs_store_id:_this.menForm.cs_store_id
                            }).then(Er =>{
                                console.log(Er)
                                if(Er.return_code == 200){
                                    _this._storeList(_this.pingRow)
                                    _this.zhangShow = false
                                    _this.$message({
                                        message: "添加成功",
                                        type: "success",
                                    });
                                }
                            })
                        }
                        
                    } else {
                        return false;
                    }
                });
            },
            dellMen(row){
                let _this = this
                this.$confirm('确认删除？', '删除', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    delStore({
                        store_id:row.id,
                        brand_id:_this.pingRow.id
                    }).then(Er =>{
                        if(Er.return_code == 200){
                            _this._storeList(_this.pingRow)
                            _this.zhangShow = false
                            _this.$message({
                                message: "删除成功",
                                type: "success",
                            });
                        }
                    })
                    
                })
            }

        }
    }
</script>

<style lang="scss" scoped>
    .stores{
        height: 100%;
        .stores-title{
            display: flex;
            align-items: center;
            height: 80px;
            >span{
                font-size: 20px;
                margin-top: -2px;
                margin-right: 10px;
            }
        }
        .cont{
            display: flex;
            height: calc(100% - 80px);
            width: 100%;
            .cont-let{
                height: 100%;
                width: 250px;
                overflow: auto;
                >li{
                    padding: 0px 12px;
                    height: 40px;
                    display: flex;
                    align-items: center;
                    >div{
                        &:nth-child(1){
                            width: calc(100% - 40px);
                        }
                         &:nth-child(2){
                            color: #409EFF;
                            width:40px;
                            text-align: right;
                            &:hover{
                                cursor: pointer;
                            }
                        }
                    }
                    &:hover{
                        cursor: pointer;
                    }
                }
                >.clickClick{
                    background: #e8f3fe;
                    color: #409EFF;
                }
            }
            .cont-rnt{
                width: calc(100% - 300px);
                margin-left: 50px;
                .cont-rnt-title{
                    height: 50px;
                    display: flex;
                    align-items: center;
                    margin-bottom: 10px;
                    >span{
                        font-size: 20px;
                        margin-top: -2px;
                        margin-right: 10px;
                    }
                }

            }
        }
    }
    .pingAnniu{
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }
    .caoStyle{
        display: flex;
        justify-content: center;
        >span{
            color: #409EFF;
            &:nth-child(1){
                margin-right: 15px;
            }
            &:hover{
                cursor: pointer;
            }
        }
    }
</style>